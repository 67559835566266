<script>
  export let style = '';
</script>

<h3 {style}>
  <span><slot /></span>
</h3>

<style>
  span {
    padding: 2px 16px 2px 4px;
    background-color: var(--red);
    color: var(--black);
  }
</style>