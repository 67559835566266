<script>
  export let style = '';
  export let href = '#';
  export let color = '';
</script>

<a {style} class={color} {href} target="_blank" rel="noopener noreferrer">
  <slot />
</a>

<style>
  a, a:visited {
    position: relative;
    display: inline-block;
    padding: 8px 16px 8px;
    font-family: Loved by the King, cursive;
    font-size: 21px;
    font-weight: 100;
    letter-spacing: 1px;
    text-align: center;
    text-decoration: none;
    color: var(--glowing-blue);
    background-color: transparent;
    border: 1px solid var(--glowing-blue);
    cursor: url("https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/icon-pointer.png?v=1669669755591"), pointer;
    transform: translateY(0px);
    transition: 0.35s;
  }

  a:after {
    content: "";
    z-index: -1;
    position: absolute;
    top: 0px;
    left: 0px;
    width: 100%;
    height: 100%;
    opacity: 0;
    transition: 0.35s;
  }
  
  a.red:after {
    background-color: var(--red);
  }
  
  a.yellow:after {
    background-color: var(--yellow);
  }
  
  a.blue:after {
    background-color: var(--blue);
  }
  
  a.purple:after {
    background-color: var(--purple);
  }
  
  a:hover {
    font-weight: 400;
    border: 1px solid transparent;
    color: var(--black);
    transform: translateY(-8px);
  }

  a:hover:after {
    opacity: 1;
  }
  
  @media only screen and (max-device-width: 480px) {
    a {
      margin-bottom: 16px;
      width: 100%;
    }
  }
</style>