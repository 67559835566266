<script>
  export let style = '';
  const date = new Date();
  const year = date.getFullYear();
</script>

<footer {style}>{year} by beckett rowan; built in <a href="https://svelte.dev/">svelte</a> using <a href="https://glitch.com/">glitch</a>.</footer>

<style>
  footer {
    width: 100%;
    font-size: 14px;
    text-align: center;
    opacity: 0.75;
  }
</style>