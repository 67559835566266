<script>
  export let style = '';
</script>

<p {style}>
  <slot />
</p>

<style>
  p {
    font-style:italic;
    font-size:16px;
  }
</style>