<script>
  import Logo from './components/Logo.svelte';
  import About from './components/About.svelte';
  import Divider from './components/Divider.svelte';
  import Gallery from './components/Gallery.svelte';
  import SendOff from './components/SendOff.svelte';
  import Footer from './components/Footer.svelte';
</script>

<main>
  <Logo width="350" height="350" />
  <article>
      <header class="hidden-a11y">
        <h1>
          beckett rowan's portfolio
        </h1>
      </header>

    	<About />
    
      <Divider style="margin-top:64px;margin-bottom:64px;"/>
    
      <Gallery />
    
      <SendOff />
  </article>
  <Footer style="margin-top:64px;margin-bottom:64px;"/>
</main>

<style>
	main {
		display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
	}
</style>