<script>
  import Bold from './Bold.svelte';
  import Button from './Button.svelte';
</script>

<section>
  <header class="hidden-a11y">
    <h2>
      about me
    </h2>
  </header>
  
  <p style="margin-bottom:32px;font-style:italic;font-size:16px;text-align:center;">
    they/them
  </p>

  <p>
    i'm a <Bold>game designer</Bold> and <Bold>software engineer</Bold> living in maryland. i craft weird websites and sometimes arrange words in pleasurably unsettling ways.
  </p>

  <p>
    i don't do social media; <a href="mailto:beckett.kl.rowan@gmail.com" target="_blank" rel="noopener noreferrer">email me</a> or find me on <a href="https://www.linkedin.com/in/beckettrowan/" target="_blank" rel="noopener noreferrer">linkedin</a>.
  </p>
  
  <div style="margin-top:64px;">
    <Button color="yellow" href="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/beckett-rowan-resume.pdf?v=1669513353093">
      see my resume
    </Button>

    <Button color="purple" href="https://beckettrowan.itch.io">
      see my games
    </Button>
  </div>
</section>

<style>
  section {
    width: 450px;
  }
  
  p {
    margin: 16px 0px;
    width: 100%;
  }
  
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 32px;
    width: 100%;
  }
  
  @media only screen and (max-device-width: 480px) {
    section {
      width: 300px;
    }
  }
</style>