<script>
  export let style = '';
  export let src = '';
  export let alt = '';
</script>

<div {style}>
  <img {src} tabindex="0" alt="" />
  <p>
    <span><slot /></span>
  </p>
</div>

<style>
  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    width: 100%;
  }

  img {
    padding: 2px;
    max-width: 100%;
    border: 1px solid var(--purple);
    transform: scale(1);
    transition: 0.5s ease-in-out;
    cursor: url("https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/icon-pointer.png?v=1669669755591"), pointer;
  }

  img:focus {
    max-width: 200%;
    cursor: url("https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/icon-cursor.png?v=1669669260327"), auto;
  }
  
  img + p {
    display: none;
  }
  
  img:focus + p {
    display: block;
    margin-top: 16px;
    padding: 0px 4px;
    width: 100%;
    font-weight: 500;
    line-height: 150%;
    color: var(--black);
    opacity: 0.85;
  }
  
  p span {
    background-color: var(--purple);
  }
</style>