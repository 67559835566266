<script>
  export let style = '';
</script>

<div {style} />
  
<style>
  div {
    width: 100%;
    height: 1px;
    border-bottom: 1px solid var(--glowing-blue);
  }
</style>
