<script>
  import Button from './Button.svelte';
</script>

<section>
  <header class="hidden-a11y">
    <h2>
      send off
    </h2>
  </header>

  <div style="margin-top:64px;">
    <Button color="purple" href="mailto:beckett.kl.rowan@gmail.com">
      send me a message
    </Button>
    
    <Button color="purple" href="https://beckettrowan.itch.io">
      see my other games
    </Button>
  </div>
</section>

<style>
  section {
    width: 450px;
  }
  
  div {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-between;
    padding: 0px 32px;
    width: 100%;
  }
  
  @media only screen and (max-device-width: 480px) {
    section {
      width: 300px;
    }
  }
</style>