<script>
  import GalleryHeader from './GalleryHeader.svelte';
  import GalleryDate from './GalleryDate.svelte';
  import GalleryRole from './GalleryRole.svelte';
  import GalleryImage from './GalleryImage.svelte';
  
  import Bold from './Bold.svelte';
  import Button from './Button.svelte';
</script>

<section>
  <header class="hidden-a11y">
    <h2>
      gallery of work
    </h2>
  </header>
  
  <GalleryHeader style="margin-bottom:16px;">goodbye.monster</GalleryHeader>
  <GalleryDate>2022-ongoing</GalleryDate>
  <GalleryRole>producer, designer, & software engineer</GalleryRole>
  
  <GalleryImage style="margin-top:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/gbm-1.png?v=1669670994934">
    the aesthetics of goodbye.monster were inspired by web 1.0 and mysterious text adventures like Zork.
  </GalleryImage>
  
  <p style="margin-top:32px;">
    <a href="https://goodbye.monster" target="_blank" rel="noopener noreferrer">goodbye.monster</a> is a study of creature collecting and caring games, of MMOs and MUDs, of losing something you knew was temporary, of nostalgia, and of designing for something that could be designed forever.
  </p>
  <p style="margin-bottom:32px;">
    created with a small team of four, with fifth and sixth members filtering in and out. i am acting as the de facto tech lead and producer of the team although we operate without hierarchy and with complete collaboration on design choices.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/gmb-2.png?v=1669670995556">
    various interactable particle effects invite the player to explore with their mouse and make the web tactile.
  </GalleryImage>
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/gbm-3.png?v=1669670995271">
    small moments of interactive choice are embedded in the environment. these storylets allow players to look for resources but don't attempt to define the world for them. 
  </GalleryImage>
  
  <p>
    <Bold>what you need to know</Bold> is that i have contributed graphic design, writing, research, frontend and backend architecture, and systems design to this wondrously overscoped labor of love, and it is the culmination of all the dreams i have had for myself since first logging on to Neopets in 1998.
  </p>
  
  <p>
    anticipated completion (as far as anything ever is) is May 2023.
  </p>
  
  <div style="margin-top:32px;margin-bottom:64px;">
    <Button color="blue" href="https://goodbye.monster">
      play it here
    </Button>
  </div>

  <div style="margin-bottom:64px;border-bottom:2px solid var(--yellow);">
      <a href="#" style="width:100%;text-align:center;text-decoration:none;">^^^</a>
  </div>

  <GalleryHeader style="margin-bottom:16px;">leftover soup</GalleryHeader>
  <GalleryDate>2021</GalleryDate>
  <GalleryRole>designer & editor</GalleryRole>
  
  <GalleryImage style="margin-top:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/soup-title.png?v=1669676786790">
    leftover soup tells its entire narrative through floating textual annotations in the environment.
  </GalleryImage>
  
  <p style="margin-top:16px;margin-bottom:32px;">
    a walking simulator about the nature of collaboration, made in collaboration with two other designers.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/soup-1.png?v=1669676784760">
    a lot of the environments in leftover soup are designed by collage. at any time, one of the designers could open the project, add what they want, and push it. we simply trusted each other's instincts on building out this narrative.
  </GalleryImage>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/soup-2.png?v=1669676785745">
    the "dialogue" in leftover soup was written specifically to make sure that the style of speaking was easily distinguishable; both characters have clear motivations and conflicting desires.
  </GalleryImage>
  
  <p>
    you can read my write up of the project on <a href="https://rookliu.itch.io/leftover-soup" target="_blank" rel="noopener noreferrer">itch.io</a>.
  </p>
  
  <p style="margin-bottom:32px;">
    <Bold>what you need to know</Bold> is that i will always care more about the people i have made something with than what we have made. projects can be triaged, but people are much harder to repair.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/soup-4.png?v=1669676786541">
    the most interesting part of designing a walking sim was finding different ways for a body in space to be a mechanic.
  </GalleryImage>
  
  <div style="margin-top:32px;margin-bottom:64px;">
    <Button color="red" href="https://rookliu.itch.io/leftover-soup">
      play it here
    </Button>
  </div>
  
  <div style="margin-bottom:64px;border-bottom:2px solid var(--yellow);">
    <a href="#" style="width:100%;text-align:center;text-decoration:none;">^^^</a>
  </div>

  <GalleryHeader style="margin-bottom:16px;">What Happened in the Well</GalleryHeader>
  <GalleryDate>2021</GalleryDate>
  <GalleryRole>writer, designer, & developer</GalleryRole>
  
  <GalleryImage style="margin-top:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/well-itch.png?v=1669679892148">
    the characters were designed on a whiteboard and scanned into Figma, where they were built up with layers of different public domain works and text from one of my favorite novels.
  </GalleryImage>
  
  <p style="margin-top:32px;margin-bottom:32px;">
    a short & replayable visual novel about an event no one can agree on. why do we tell a story the way we do? when is a story more real than the event it's based on? inspired heavily by Rashomon and other stories of ambiguity.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/well-1.png?v=1669679890175">
    each replay allows a reader to see and compare two stories simultaneously. the primary character sets the color scheme, and the secondary character is always masked with the complementary color and mirrored.
  </GalleryImage>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/well-2.png?v=1669679891091">
    each character is written to have a specific voice and story details not provided by other characters. even if every playthrough is finished, it's impossible to say for certain what happened.
  </GalleryImage>
  
  <p>
    you can read my write up of the project on <a href="https://beckettrowan.itch.io/what-happened-in-the-well" target="_blank" rel="noopener noreferrer">itch.io</a>.
  </p>
  
  <p>
    <Bold>what you need to know</Bold> is that i write about the impossibility of truth. i am simply more interested in what players interpret than in being perfectly understood.
  </p>

  <div style="margin-top:32px;margin-bottom:64px;">
    <Button color="red" href="https://beckettrowan.itch.io/what-happened-in-the-well">
      play it here
    </Button>
  </div>
  
  <div style="margin-bottom:64px;border-bottom:2px solid var(--yellow);">
    <a href="#" style="width:100%;text-align:center;text-decoration:none;">^^^</a>
  </div>

  <GalleryHeader style="margin-bottom:16px;">Oh--That Wicked Creature</GalleryHeader>
  <GalleryDate>2014 & 2021</GalleryDate>
  <GalleryRole>designer & developer</GalleryRole>
  
  <GalleryImage style="margin-top:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/twc-2.febb2bed.png?v=1669680446902">
    Oh--That Wicked Creature is fully responsive and works with a touch screen.
  </GalleryImage>
  
  <p style="margin-top:32px;margin-bottom:32px;">
    a small personal project, made in my undergrad and then remade after spending 4 years as a web developer to reflect on how far i've come. inspired by dress up games and the web cartoon Making Fiends.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/s-3.4f08e431.png?v=1669680445207">
    this knowing beast made by the unbeatable Blane Hadley.
  </GalleryImage>
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/s-1.fad1ee2a.png?v=1669680444680">
    this sample creature is my wife's favorite that i have made, and so it has earned a spot here: the first and most beloved example of what you might endeavor to make.
  </GalleryImage>
  
  <p>
    the website guides you through a poem describing a terrible creature before allowing you to make your own awful monster and subsequently describe it with fridge magnet-like poetry. many parts of the generators are randomized, and the page does not save your progress, inviting you to settle for an option with which you could make peace, commit many small mistakes, and live with the consequences of your actions.
  </p>
  
  <p style="margin-bottom:32px;">
    <Bold>what you need to know</Bold> is that i make small experiences about weird emotions. i consider every detail, from the sounds to the visuals to the underlying technologic foundations, and there are no skills that are not worth pursuing at least once.
  </p>
  
  <GalleryImage style="margin-bottom:32px;" src="https://cdn.glitch.global/245df91d-1e1e-4ead-8630-b866b66b1024/s-4.bbb4da41.png?v=1669680445543">
    my personal favorite creation: the bird that my wife hates. this one is the social media card for the website if you share on twitter, &c.
  </GalleryImage>
  
  <div style="margin-top:32px;margin-bottom:64px;">
    <Button color="red" href="https://that-wicked-creature.glitch.me/">
      play it here
    </Button>
    <span style="display:block;margin-top:8px;font-size:14px;font-style:italic;">please <a href="https://that-wicked-creature.glitch.me/" target="_blank" rel="noopener noreferrer">make a treacherous little beast</a> and then also <a href="mailto:beckett.kl.rowan@gmail.com" target="_blank" rel="noopener noreferrer">send it to me</a></span>
  </div>
  
  <div style="margin-bottom:64px;border-bottom:2px solid var(--yellow);">
    <a href="#" style="width:100%;text-align:center;text-decoration:none;">^^^</a>
  </div>
</section>

<style>
  section {
    width: 450px;
  }
  
  p {
    margin: 16px 0px;
    width: 100%;
  }

  div {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 0px 32px;
    width: 100%;
  }

  @media only screen and (max-device-width: 480px) {
    section {
      width: 300px;
    }
  }
</style>